<script lang="ts" setup>
import emblaCarouselVue from 'embla-carousel-vue';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'lucide-vue-next';
import { onMounted } from 'vue';

withDefaults(defineProps<{
  arrowButtonClass?: ClassProp;
  containerClass?: ClassProp;
  loop?: boolean;
  nextArrowButtonClass?: ClassProp;
  prevArrowButtonClass?: ClassProp;
  showDots?: boolean;
  slideIndex?: number;
}>(), { loop: false,showDots: false, })

const emit = defineEmits<{
  onSlideChange: [slideIndex: number];
}>();

const [
  emblaRef,
  emblaApi,
] = emblaCarouselVue({
  loop: __props.loop,
  slidesToScroll: 'auto',
}, [ WheelGesturesPlugin() ]);

const hasPrev = ref(emblaApi.value?.canScrollPrev());
const hasNext = ref(emblaApi.value?.canScrollNext());
const dots = ref(0);
const activeSlideIndex = ref(0);

const toggleNavigationButtons = () => {
  if (emblaApi.value) {
    hasPrev.value = emblaApi.value.canScrollPrev();
    hasNext.value = emblaApi.value.canScrollNext();
    dots.value = emblaApi.value.scrollSnapList().length;
    activeSlideIndex.value = emblaApi.value.selectedScrollSnap();

    emit('onSlideChange', emblaApi.value.selectedScrollSnap());
  }
};

onMounted(() => {
  if (emblaApi.value) {
    emblaApi.value
      .on('select', toggleNavigationButtons)
      .on('init', toggleNavigationButtons)
      .on('reInit', toggleNavigationButtons);
  }
});

const onNext = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollNext();
  }
};

const onPrev = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollPrev();
  }
};

watch(() => __props.slideIndex, (newIndex) => {
  if (emblaApi.value && newIndex !== undefined && newIndex !== emblaApi.value.selectedScrollSnap()) {
    emblaApi.value.scrollTo(newIndex);
  }
});
</script>

<template>
  <div
    ref="emblaRef"
    class="relative"
  >
    <div :class="cn('embla__container flex gap-x-4', containerClass)">
      <slot />
    </div>

    <button
      type="button"
      :class="cn('bg-dark/30 hover:bg-dark/50 absolute left-4 top-1/2 flex size-10 -translate-y-5 items-center justify-center rounded-full text-white backdrop-blur-md transition-all duration-300 hover:scale-110', [hasPrev ? 'scale-100 opacity-100' : 'scale-0 opacity-0'], arrowButtonClass, prevArrowButtonClass)"
      @click="onPrev()"
    >
      <ArrowLeftIcon class="size-5" />
    </button>

    <button
      type="button"
      :class="cn('bg-dark/30 hover:bg-dark/50 absolute right-4 top-1/2 flex size-10 -translate-y-5 items-center justify-center rounded-full text-white backdrop-blur-md transition-all duration-300 hover:scale-110', [hasNext ? 'scale-100 opacity-100' : 'scale-0 opacity-0'], arrowButtonClass, nextArrowButtonClass)"
      @click="onNext()"
    >
      <ArrowRightIcon class="size-5" />
    </button>
  </div>

  <div
    v-if="showDots && dots > 1"
    class="mt-4 flex items-center justify-center gap-2"
  >
    <div
      v-for="i in dots"
      :key="i"
      :class="cn('size-2 rounded-full cursor-pointer', [activeSlideIndex === i - 1 ? 'bg-dark/50' : 'bg-dark/20'])"
      @click="emblaApi?.scrollTo(i - 1)"
    />
  </div>
</template>
